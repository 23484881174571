<template>
  <slot />
</template>

<script setup lang="ts">
import { useAuthState } from "@frontegg/vue";
import Gleap from "gleap";
import { onMounted, onBeforeUnmount, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { agentSettingsStore } from "stores/agent-settings-global-store";
import { SupportedLocale } from "../composables/useLocaleHelpers";
import { tenantInfoStore } from "stores/tenant-info-global-store";

const authState = useAuthState();
const router = useRouter();
const { locale } = useI18n();
const $q = useQuasar();

const localeToGleapLanguage: Record<SupportedLocale, string> = {
  en: "en",
  no: "nb",
};

let currentLanguage: string = "";

const identifyUser = () => {
  if (!authState.user) {
    console.error("No user data, skipping Gleap identify");
    return;
  }

  const agentState = agentSettingsStore.getState();
  const tenantState = tenantInfoStore.getState();

  Gleap.identify(`${authState.user.id}`, {
    name: agentState.name,
    email: authState.user.email,
    companyId: authState.user.tenantId,
    companyName: authState.tenantsState.activeTenant?.name,
    // Passing the createdAt property allows you to set the correct createdAt date. (optional)
    createdAt: authState.user.createdAt,
    customData: {
      role: agentState.perTenant.role,
      isFirstAgentInTenant: agentState.perTenant.isFirstAgentInTenant,
      hasAtLeastOneConversation: tenantState.onboardingStatus.hasAtLeastOneConversation,
      hasInstalledWidgetOnWebsite: tenantState.onboardingStatus.hasInstalledWidgetOnWebsite,
    },
  });
};

onMounted(() => {
  if (!authState.user) {
    console.error("No user data, skipping Gleap init");
    return;
  }

  if (Gleap.getInstance()?.initialized === true) {
    console.log("Gleap is already initialized");

    if (!Gleap.isUserIdentified()) {
      console.log("Gleap was initialized, but user not identified -- identifying...");
      identifyUser();
    }

    return;
  }

  let environment: "dev" | "prod" | "staging" = "dev";

  if (process.env.STAGING) {
    environment = "staging";
  } else if (process.env.PROD) {
    environment = "prod";
  }

  console.log("initializing gleap");

  const gleapLanguage = localeToGleapLanguage[locale.value as SupportedLocale];
  currentLanguage = gleapLanguage;
  Gleap.setLanguage(gleapLanguage);

  Gleap.setEnvironment(environment);
  if (process.env.appVersion) {
    Gleap.setAppVersionCode(process.env.appVersion);
  }

  const isMobile = $q.platform.is.mobile ?? false;

  Gleap.showFeedbackButton(!isMobile);

  Gleap.initialize("ifFDAkuLJq9LXBOyWLjk3des8OjzuLoU");

  identifyUser();

  Gleap.registerCustomAction(async customAction => {
    if (customAction.name === "REDIRECT_TO_CHAT_SETTINGS") {
      await router.push({
        name: "settings-channels-chat",
      });
      return;
    }

    if (customAction.name === "REDIRECT_TO_AGENT_SETTINGS") {
      await router.push({
        name: "settings-agents",
      });
      return;
    }

    if (customAction.name === "REDIRECT_TO_NOTIFICATION_SETTINGS") {
      await router.push({
        name: "settings-agent-notifications",
      });
      return;
    }

    if (customAction.name === "OPEN_VIDEO_HELP_SECTION") {
      Gleap.openHelpCenterCollection("1-explainer-videos", true);
      return;
    }
  });

  Gleap.on("productTourCompleted", ({ tourId }) => {
    if (tourId === "65bcb345032687251395c06e" && agentSettingsStore.getState().perTenant.isFirstAgentInTenant) {
      console.log("Initial tour completed -- starting checklist");
      Gleap.startChecklist("65bcb73406621f7c9b61db38");
    }
  });

  window.Gleap = Gleap;
});

onBeforeUnmount(() => {
  if (!Gleap.getInstance()?.initialized) {
    console.debug("Gleap isn't initialized");
    return;
  }

  Gleap.clearIdentity();
  console.debug("Cleared identity");
  Gleap.destroy();
});

watch(locale, newLocale => {
  const gleapLanguage = localeToGleapLanguage[newLocale as SupportedLocale];

  if (gleapLanguage !== currentLanguage) {
    console.log("Changed Gleap language to", gleapLanguage);
    currentLanguage = gleapLanguage;
    Gleap.setLanguage(gleapLanguage);
  }
});
</script>

<style scoped lang="scss"></style>
